import { useEffect, useState } from 'react'

import { lg, md, sm, tablet, xs } from '../../styles/variables.module.scss'

/**
 * Accepts a list of media queries, returns a corresponding value
 * from the result array for first query it matches.
 * @param queries list of media queries, e.g. `(max-width: 10px)` to check for. First one to match wins.
 * @param results list of results. Once a query matches, result with the same index is returned from this array.
 * @param defaultValue value for server-side rendering and for when no query matches.
 * It's wise to always assume mobile first, since it affects performance/SEO metrics.
 */
export const useMedia = <T>(
    queries: string[],
    results: T[],
    defaultValue: T
): T => {
    const [value, setValue] = useState(defaultValue)

    const match = () =>
        results[queries.findIndex((q) => window.matchMedia(q).matches)]

    const updateValue = () => setValue(match)

    useEffect(() => {
        updateValue()
        window.addEventListener('resize', updateValue)
        return () => window.removeEventListener('resize', updateValue)
    }, [])

    return value
}

export const useIsMobile = () =>
    useMedia([maxWidth(BREAKPOINTS['large-tablet'])], [true], true)

export const BREAKPOINTS = new Proxy(
    {
        large: lg,
        'large-tablet': tablet,
        medium: md,
        small: sm,
        'x-small': xs,
    } as const,
    {
        get(target, key) {
            if (
                typeof key === 'string' &&
                key in target &&
                !target[key] &&
                !process.env.STORYBOOK
            ) {
                throw new Error(`Breakpoint ${key} not defined`)
            }
            return target[key]
        },
    }
)

/** Returns a (max-width: value) media query. */
export const maxWidth = (value: string) => `(max-width: ${value})`

/** Returns a (min-width: value) media query. */
export const minWidth = (value: string) =>
    `(min-width: ${parseInt(value) + 1}px)`

/** Returns a (min-width: value) and (max-width: value) media query. */
export const minMaxWidth = (min: string, max: string) =>
    `${minWidth(min)} and ${maxWidth(max)}`
