import { useChain, useSpring, useSpringRef } from '@react-spring/web'

import { gapSmall } from '../../styles/variables.module.scss'
import type { Axis } from '../../types/styles'

/**
 * Snackbar pretty bounce in/out.
 * @param axis You might want to use vertical axis for mobile.
 */
export const useSnackbarBounce = (axis: Axis, visible = false) => {
    const state = states[axis][visible ? 'in' : 'out']
    const boxRef = useSpringRef()
    const box = useSpring({
        ref: boxRef,
        ...state.box,
        config: {
            tension: 500,
            friction: 20,
            precision: 0.1,
        },
    })

    const portalRef = useSpringRef()
    const portal = useSpring({
        ref: portalRef,
        ...state.portal,
        config: {
            tension: 125,
            friction: 20,
            precision: 0.1,
        },
    })

    useChain(visible ? [portalRef, boxRef] : [boxRef, portalRef], [0, 0.1])

    return { box, portal }
}

const smallMargin = parseInt(gapSmall) || 0
const negativeMargin = -200

const vertical = {
    in: {
        box: {
            top: smallMargin,
            right: smallMargin,
            opacity: 1,
        },
        portal: {
            opacity: 1,
        },
    },
    out: {
        box: {
            top: negativeMargin,
            right: smallMargin,
            opacity: 0,
        },
        portal: {
            opacity: 0,
        },
    },
}

const horizontal = {
    in: {
        box: vertical.in.box,
        portal: vertical.in.portal,
    },
    out: {
        box: {
            top: smallMargin,
            right: negativeMargin,
            opacity: 0,
        },
        portal: vertical.out.portal,
    },
}

const states = { vertical, horizontal }
